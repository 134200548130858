import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/menu',
    name: 'menu',
    component: () => import('@/pages/menu.vue')
  },
  {
    path: '/basket',
    name: 'basket',
    component: () => import('@/pages/basket.vue')
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('@/pages/registration.vue')
  },
  {
    path: '/ordering',
    name: 'ordering',
    component: () => import('@/pages/ordering.vue')
  },
  {
    path: '/edit-user-info',
    name: 'edit-user-info',
    component: () => import('@/pages/edit-user-info.vue')
  },
  {
    path: '/select-branch',
    name: 'select-branch',
    component: () => import('@/pages/select-branch.vue')
  },
  {
    path: '/select-delivery-location',
    name: 'select-delivery-location',
    component: () => import('@/pages/select-delivery-location.vue')
  },
  {
    path: '/add-location',
    name: 'add-location',
    component: () => import('@/pages/add-location.vue')
  },
  {
    path: '/edit-location',
    name: 'edit-location',
    component: () => import('@/pages/edit-location.vue')
  },
  {
    path: '/combos/:id',
    name: 'combo',
    component: () => import('@/pages/combo.vue')
  },
  {
    path: '/pizzas/:id',
    name: 'pizza',
    component: () => import('@/pages/pizza.vue')
  },
  {
    path: '/products/:id',
    name: 'product',
    component: () => import('@/pages/product.vue')
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/pages/orders.vue')
  },
  {
    path: '/orders/:key',
    name: 'order',
    component: () => import('@/pages/order.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
