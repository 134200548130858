import axios from "axios"

axios.defaults.baseURL = '/api/';
axios.interceptors.response.use(
    response => {
        return response;
    },
    err => {
        if(err.response.status === 401){
            window.Telegram.WebApp.close();
        }
        return Promise.reject(err);
    }
)