import { createStore } from 'vuex'

export default createStore({
  state: {
    user: null,
    text: null,
    is_instant: false,
    is_order_type_changing_available: false,
    pizza_order_items: [],
    product_order_items: [],
    combo_order_items: [],
    pizza_order_item_index_for_change: null,
    combo_order_item_index_for_change: null,
  },
  getters: {
  },
  mutations: {
    setUser(state, user){state.user = user},
    setText(state, text){state.text = text},
    setIsInstant(state, is_instant){state.is_instant = is_instant},
    setIsOrderTypeChangingAvailable(state, is_order_type_changing_available){state.is_order_type_changing_available = is_order_type_changing_available},

    addPizzaOrderItem(state, new_pizza_order_item){
      let pizza_order_item = state.pizza_order_items.filter(pizza_order_item => (
        pizza_order_item.pizza.id === new_pizza_order_item.pizza.id &&
        pizza_order_item.pizza_price.id === new_pizza_order_item.pizza_price.id &&
        pizza_order_item.selected_additives.every((val, i) => val === new_pizza_order_item.selected_additives[i]) &&
        pizza_order_item.excluded_ingredients.every((val, i) => val === new_pizza_order_item.excluded_ingredients[i])
      ))[0]
      if(pizza_order_item){
        pizza_order_item.current_price = new_pizza_order_item.current_price;
        pizza_order_item.amount += new_pizza_order_item.amount;
      }else{
        state.pizza_order_items.push(new_pizza_order_item);
      }
    },
    setPizzaOrderItem(state, {pizza_order_item_index, pizza_order_item}){state.pizza_order_items[pizza_order_item_index] = pizza_order_item},
    removePizzaOrderItem(state, pizza_order_item_index){state.pizza_order_items.splice(pizza_order_item_index, 1)},
    setPizzaOrderItemIndexForChange(state, pizza_order_item_index){state.pizza_order_item_index_for_change = pizza_order_item_index},

    addProductOrderItem(state, new_product_order_item){
      let product_order_item = state.product_order_items.filter(product_order_item => (
        product_order_item.product.id === new_product_order_item.product.id &&
        product_order_item.product_price.id === new_product_order_item.product_price.id
      ))[0]
      if(product_order_item){
        product_order_item.amount += new_product_order_item.amount;
      }else{
        state.product_order_items.push(new_product_order_item);
      }
    },
    setProductOrderItem(state, {product_order_item_index, product_order_item}){state.product_order_items[product_order_item_index] = product_order_item},
    removeProductOrderItem(state, product_order_item_index){state.product_order_items.splice(product_order_item_index, 1)},

    addComboOrderItem(state, new_combo_order_item){
      let combo_order_item = state.combo_order_items.filter(combo_order_item => (
        combo_order_item.combo.id === new_combo_order_item.combo.id
      ))[0]
      if(combo_order_item){
        combo_order_item.current_price = new_combo_order_item.current_price;
        combo_order_item.amount += new_combo_order_item.amount;
      }else{
        state.combo_order_items.push(new_combo_order_item);
      }
    },
    setComboOrderItem(state, {combo_order_item_index, combo_order_item}){state.combo_order_items[combo_order_item_index] = combo_order_item},
    removeComboOrderItem(state, combo_order_item_index){state.combo_order_items.splice(combo_order_item_index, 1)},
    setComboOrderItemIndexForChange(state, combo_order_item_index){state.combo_order_item_index_for_change = combo_order_item_index},

    clearBasket(state){
      state.pizza_order_items = [];
      state.product_order_items = [];
      state.combo_order_items = [];
    },
    setLoadedBasket(state, {pizza_order_items, product_order_items, combo_order_items}){
      state.pizza_order_items = pizza_order_items;
      state.product_order_items = product_order_items;
      state.combo_order_items = combo_order_items;
    },
  },
  actions: {
  },
  modules: {
  }
})
